
export function getSiteUrl () {
  switch (getEnvironment()) {
    case 'local':
      return 'http://localhost:3002/api';
    case 'development':
      return 'https://devapi.fillactive.ca';
    case 'production':
      return 'https://api.fillactive.ca';
    default:
      return '';
  }


  function getEnvironment () {
    const hostname = window.location.hostname;
    if (hostname === 'localhost') {
      return 'local';
    } else if (hostname === 'devxms.fillactive.ca') {
      return 'development';
    } else if (hostname === 'xms.fillactive.ca') {
      return 'production';
    } else {
      return 'unknown';
    }
  }
}
