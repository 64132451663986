<template>
  <div>
    <div
      v-if="isExporting"
      class="export-actionblocker"
    />
    <div class="d-flex align-center mb-3">
      <!-- Filters toggle -->
      <v-btn
        v-if="typeConfig.filters !== undefined && filtersWithOptions.length > 0 || allowSearch"
        class="pa-0"
        small
        text
        @click.stop="toggleFilters"
      >
        <v-icon>mdi-filter-variant-{{ showFilters ? 'minus' : 'plus' }}</v-icon>
        &nbsp;&nbsp;{{ showFilters ? $t('ui.hide_filters') : $t('ui.show_filters') }}
      </v-btn>
      <v-spacer />
      <!-- Open add modale button -->
      <v-btn
        v-if="!disableAddItem"
        class="ml-3 pl-0 pr-0 btn-add"
        color="primary"
        rounded
        @click="openAddDialog"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <!-- Open export modale button -->
      <v-menu
        v-if="!disableExportItem && rowsNotEmpty"
        :disabled="isExporting === true"
        :close-on-content-click="true"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            :loading="isExporting"
            color="primary"
            class="ml-3 pl-0 pr-0 export-menu__button"
            rounded
            dark
            v-on="on"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            v-for="action in exportOptions"
            :key="action.label"
            @click="handleExportClick(action.exportType)"
          >
            <!-- List icon -->
            <v-list-item-icon class="mr-2">
              <v-icon
                small
                v-text="action.icon"
              />
            </v-list-item-icon>
            <!-- List content -->
            <v-list-item-content>
              <v-list-item-title v-t="action.label" />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <!-- Filters form -->
    <filters-form
      v-if="showFilters"
      :items="filtersWithOptions"
      :value="selectedFilters"
      :query.sync="search"
      :search="allowSearch"
      :type-name="typeName"
      class="mb-4"
      @update:value="updateFilters"
    />
    <div class="table-wrapper elevation-1 white">
      <!-- Listing treeview -->
      <tree-table
        v-if="viewType === 'treeview'"
        :items.sync="rows"
        :item-children="typeName"
        :fields="treeviewFields"
        :draggable-max-level="typeConfig.treeviewMaxLevel"
        :draggable-rules="typeConfig.treeviewRules"
        :loading="isLoading"
        :clickable-rows="editIsEmpty === false"
        draggable
        @moveitem="handleMoveItem"
        @update:item-field="handleUpdateItemField"
        @click:row="handleTableRowClick($event)"
      >
        <template #top-left>
          <h3
            v-if="rowsNotEmpty"
            class="subtitle-2"
            v-html="`${nRows.count} ${$t('ui.results')}`"
          />
        </template>
        <template #top-right>
          <view-type-toggle
            v-if="typeConfig.treeview === true || hasImages === true"
            v-model="viewType"
            :show-treeview="typeConfig.treeview === true"
            :show-thumbnails="hasImages === true"
          />
        </template>
        <template
          v-if="showActionsColumn"
          #actions="{ item }"
        >
          <v-icon
            v-if="!disableEditItem"
            :disabled="editIsEmpty === true"
            class="ml-3"
            small
            @click="goToEdit(typeConfig.id, item.id, typeName)"
          >
            {{ typeConfig.saveable ? 'edit' : 'remove_red_eye' }}
          </v-icon>
          <v-icon
            v-if="!disableDeleteItem"
            class="ml-3"
            small
            @click="handleDeleteDialog(item)"
          >
            delete
          </v-icon>
          <v-icon
            v-if="!disableSendItem && typeName === 'participants'"
            :disabled="editIsEmpty || isLoading"
            class="ml-3"
            small
            @click.stop="handleSendAuthorisation(item)"
          >
            send
          </v-icon>
        </template>
      </tree-table>
      <!-- Listing table -->
      <data-table
        v-else
        :value="selected || []"
        :server-items-length="nRows ? nRows.count : 0"
        :items-per-page="itemsPerPage"
        :sort-desc.sync="options.descending"
        :sort-by.sync="options.sortBy"
        :footer-props="footerProps"
        :show-select="!!selected"
        :page.sync="options.page"
        :mobile-breakpoint="960"
        :loading="isLoading"
        :items="rows || []"
        :item-key="itemKey"
        :headers="headers"
        :show-images="viewType === 'thumbnails'"
        :class="{
          'is-select': !!selected,
        }"
        :hide-default-footer="typeConfig.pagination === false"
        :clickable-rows="tableHasClickableRows"
        @input="$emit('update:selected', $event)"
        @update:item-field="handleUpdateItemField"
        @update:items-per-page="handleUpdateItemsPerPage"
        @update:page="handleTableAnalytics($event, 'pagination')"
        @click:row="handleTableRowClick($event)"
      >
        <template #top-left>
          <h3
            v-if="rowsNotEmpty"
            class="subtitle-2"
            v-html="`${nRows.count} ${$t('ui.results')}`"
          />
        </template>
        <template #top-right>
          <!-- Open add modale button -->
          <view-type-toggle
            v-if="typeConfig.treeview === true || hasImages === true"
            v-model="viewType"
            :show-treeview="typeConfig.treeview === true"
            :show-thumbnails="hasImages === true"
            :type-name="typeName"
          />
        </template>
        <template
          v-if="showActionsColumn"
          #item.action="{ item }"
        >
          <v-icon
            v-if="!disableEditItem"
            :disabled="editIsEmpty || isLoading"
            class="ml-3"
            small
            @click.stop="goToEdit(typeConfig.id, item.id, typeName)"
          >
            {{ typeConfig.saveable ? 'edit' : 'remove_red_eye' }}
          </v-icon>
          <v-icon
            v-if="!disableDeleteItem"
            :disabled="isLoading"
            class="ml-3"
            small
            @click.stop="handleDeleteDialog(item)"
          >
            delete
          </v-icon>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="!disableSendItem && typeName === 'participants'"
                :disabled="editIsEmpty || isLoading"
                class="ml-3"
                small
                v-bind="attrs"
                v-on="on"
                @click.stop="handleSendAuthorisation(item)"
              >
                send
              </v-icon>
            </template>
            <span>Envoyer l'autorisation parentale</span>
          </v-tooltip>
        </template>
      </data-table>
    </div>
    <!-- Add dialog -->
    <template v-if="!disableAddItem">
      <add-item
        v-show="addItemDialog"
        :type-name="typeName"
        :add="addItemDialog"
        :hidden-add-fields="hiddenAddFields"
        :is-relation="isRelationsListing"
        @add-relation="$emit('add-relation', $event)"
        @refetch="refetch"
        @close="closeAddDialog"
      />
    </template>
    <!-- Delete Item dialog -->
    <custom-dialog
      v-model="customDialogOpen"
      :title="customDialogTitle"
      :subtitle="customDialogSubtitle"
      :show-action="customDialogShowAction"
      @confirm="customDialogAction"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { get, has, cloneDeep } from 'lodash';
import gql from 'graphql-tag';
import { defaultTypeConfig } from '../../../default-config';
import typeConfigMixin from '../../mixins/typeConfig';
import queriesMixin from '../../mixins/queries';
import routingMixin from '../../mixins/routing';
import exportsMixin from '../../mixins/exports';

import AddItem from '../Modales/AddItem';
import FiltersForm from '../FiltersForm/FiltersForm';
import TreeTable from '../TreeTable/TreeTable';
import DataTable from '../DataTable/DataTable';
import ViewTypeToggle from '../ViewTypeToggle/ViewTypeToggle';
import CustomDialog from '../../components/Modales/CustomDialog';
import { getSiteUrl } from '../../helpers/sites';

export default {
  components: {
    AddItem,
    FiltersForm,
    TreeTable,
    DataTable,
    CustomDialog,
    ViewTypeToggle,
  },
  mixins: [
    typeConfigMixin({
      typeName () {
        return this.typeName;
      }
    }),
    queriesMixin,
    routingMixin,
    exportsMixin,
  ],
  model: {
    prop: 'selected',
    event: 'update:selected'
  },
  props: {
    selected: {
      type: [Array, null],
      default: null
    },
    typeName: {
      type: String,
      required: true
    },
    itemKey: {
      type: null,
      default: 'id'
    },
    relatedFiltersString: {
      type: String,
      default: '',
    },
    cachedFilters: {
      type: Boolean,
      default: false
    },
    isRelationsListing: {
      type: Boolean,
      default: false
    },
    hiddenAddFields: {
      type: Object,
      default: () => {},
    },
    disableAddItem: {
      type: Boolean,
      default: false
    },
    disableEditItem: {
      type: Boolean,
      default: false
    },
    disableSendItem: {
      type: Boolean,
      default: false
    },
    disableDeleteItem: {
      type: Boolean,
      default: false
    },
    disableExportItem: {
      type: Boolean,
      default: false
    },
    additionalQueryParams: {
      type: Object,
      default: () => {}
    },
  },
  apollo: {
    rows: {
      query () {
        return this.getDataQuery();
      },
      result (data) {
        this.rows = get(data, 'data.rows', []);
        this.nRows = get(data, 'data.nRows', 0);
      },
      fetchPolicy: 'network-only',
    },
    filtersOptions: {
      query () {
        return this.createFiltersOptionsQuery(this.typeConfig.filters);
      },
      skip () {
        // Skip query if no gql query is returned
        return !this.createFiltersOptionsQuery(this.typeConfig.filters);
      },
      update (data) {
        return data;
      }
    },
  },
  data () {
    const { query } = this.$route;

    return {
      customDialogOpen: false,
      customDialogTitle: '',
      customDialogSubtitle: '',
      customDialogAction: () => {},
      customDialogShowAction: true,
      addItemDialog: false,
      editId: null,
      nRows: 0,
      localShowFilters: true,
      localFilters: {},
      localSearch: '',
      options: {
        sortBy: [],
        descending: [],
        page: query.page ? Number(query.page) : 1,
        itemsPerPage: null,
      },
      localViewType: '',
    };
  },
  computed: {
    ...mapState('content', ['contentLangUpdating']),
    ...mapGetters('crud', ['getTypeInfos']),
    tableHasClickableRows () {
      return this.isRelationsListing
        || (this.showActionsColumn === true && this.editIsEmpty === false);
    },
    rowsNotEmpty () {
      return this.nRows && this.nRows.count > 0;
    },
    searchFields () {
      return this.fields.filter(field => field.search === true);
    },
    allowSearch () {
      // Disable search field only if search is false in config
      return !(this.typeConfig.search === false);
    },
    showActionsColumn () {
      return !this.disableEditItem || !this.disableDeleteItem || !this.disableSendItem;
    },
    headers () {
      const headers = this.listingFields.map(field => {
        const header = {
          text: this.$t(field.label),
          sortable: !field.notsortable,
          value: field.key,
          type: field.type,
          items: field.items,
          width: get(field, 'listingStyle.columnWidth', null),
          icon: get(field, 'listingStyle.columnIcon', null),
          badge: get(field, 'listingStyle.badge', false),
          chip: get(field, 'listingStyle.chip', false),
          class: get(field, 'listingStyle.columnClass', null),
          itemText: field.listing_attribute !== undefined
            ? field.listing_attribute
            : field.itemText,
        };
        if (field.type === 'status') {
          header.props = field.props;
        }

        if (field.type === 'select') {
          if (field.props && field.props.multiple) {
            header.type = 'array';
          } else {
            header.type = 'object';
          }
        }

        return header;
      });

      if (this.showActionsColumn) {
        headers.push({ text: 'Action(s)', value: 'action', width: 150, sortable: false, align: 'right' });
      }

      return headers;
    },
    isLoading () {
      return this.contentLangUpdating || this.$apolloData.queries.rows.loading;
    },
    /**
     * Filters with options.
     * Attaches translated fetched and static filters options
     * to each filter in config and returns a new filters array.
     * @returns {Array} - The filters with options attached.
     */
    filtersWithOptions () {
      /**
       * Translate option function
       */
      const translateOption = option => ({
        value: option.value,
        text: this.$t(option.text)
      });
      if (!Array.isArray(this.typeConfig.filters)) return [];
      return this.typeConfig.filters.map(filterConfig => {
        const filter = { ...filterConfig };

        // Fetched options take priority over static options
        if (this.filtersOptions && this.filtersOptions[filter.id]) {
          filter.options = this.filtersOptions[filter.id].map(translateOption);
        } else if (filter.options) {
          filter.options = filter.options.map(translateOption);
        }

        return filter;
      });
    },
    selectedFiltersValues () {
      return Object.values(this.selectedFilters);
    },
    footerProps () {
      const itemsLength = this.nRows != null
        ? this.nRows.count
        : 0;
      const itemsPerPage = this.itemsPerPage;
      const page = this.options.page;
      const pageCount = itemsPerPage != null
        ? Math.ceil(itemsLength / itemsPerPage)
        : 1;
      const pageStart = itemsPerPage != null
          ? (page - 1) * itemsPerPage
          : 0;
      const pageStop = itemsPerPage != null
          ? page * itemsPerPage
          : (this.rows ? this.rows.length : 0);
      const itemsPerPageOptions = this.typeConfig.itemsPerPageOptions !== undefined
        ? this.typeConfig.itemsPerPageOptions
        : defaultTypeConfig.itemsPerPageOptions;

      return {
        itemsPerPageOptions: itemsPerPageOptions.map(option => {
          // Display null option as infinity
          if (option == null) {
            return { value: null, text: '∞' };
          }

          return option;
        }),
        itemsPerPageText: this.$t('generic.rows_per_page'),
        pagination: {
          itemsLength,
          itemsPerPage,
          page,
          pageCount,
          pageStart,
          pageStop,
        },
        // Disable footer page navigation when infinite
        disablePagination: this.itemsPerPage == null,
      };
    },
    hasImages () {
      return this.headers.some(header => header.type === 'image');
    },
    editIsEmpty () {
      return !(this.editFields && this.editFields.length > 0);
    },
    itemsPerPage () {
      // Use local items per page only when above 0
      if (this.options.itemsPerPage > 0) {
        return this.options.itemsPerPage;
      }

      return this.typeConfig.itemsPerPage;
    },

    // Cached data.
    // Each with a getter and setter that change behavior depending on cached filters prop.
    // They interact with the store when caching is enabled
    // otherwise they interact with local data.
    showFilters: {
      get () {
        if (this.cachedFilters) {
          return this.getTypeInfos(this.typeName, 'showFilters') || false;
        }

        return this.localShowFilters;
      },
      set (value) {
        if (this.cachedFilters) {
          this.setTypeInfo({
            typeName: this.typeName,
            key: 'showFilters',
            value
          });
        } else {
          this.localShowFilters = value;
        }
      }
    },
    selectedFilters: {
      get () {
        if (this.cachedFilters) {
          return this.getTypeInfos(this.typeName, 'filters') || {};
        }

        return this.localFilters;
      },
      set (value) {
        if (this.cachedFilters) {
          this.setTypeInfo({
            typeName: this.typeName,
            key: 'filters',
            value
          });
        } else {
          this.localFilters = value;
        }
      }
    },
    search: {
      get () {
        if (this.cachedFilters) {
          return this.getTypeInfos(this.typeName, 'search') || '';
        }

        return this.localSearch;
      },
      set (value) {
        if (this.cachedFilters) {
          this.setTypeInfo({
            typeName: this.typeName,
            key: 'search',
            value
          });
        } else {
          this.localSearch = value;
        }
      }
    },
    viewType: {
      get () {
        const defaultViewType = this.typeConfig.treeview === true ? 'treeview' : 'thumbnails';

        if (this.cachedFilters) {
          return this.getTypeInfos(this.typeName, 'viewType') || defaultViewType;
        }

        return this.localViewType || defaultViewType;
      },
      set (value) {
        if (this.cachedFilters) {
          this.setTypeInfo({
            typeName: this.typeName,
            key: 'viewType',
            value,
          });
        } else {
          this.localViewType = value;
        }
      }
    }
  },
  watch: {
    search: {
      handler () {
        this.options.page = 1;
      },
    },
    selectedFilters: {
      handler () {
        this.options.page = 1;
      },
      deep: true
    },
    typeName: {
      handler () {
        this.options.page = 1;
      },
    },
    options: {
      handler (options) {
        this.$router.push({
          ...this.route,
          query: {
            page: options.page,
          }
        });
      },
      deep: true,
    },
  },
  created () {
    this.initTypeInfos(this.typeName);
    for (const filter of this.typeConfig.filters) {
      if (filter.defaultValue !== null || filter.defaultValue !== undefined) {
        this.updateFilters({ key: filter.id, value: filter.defaultValue });
      }
    }
  },
  methods: {
    ...mapActions('crud', ['initTypeInfos', 'setTypeInfo']),
    async handleSendAuthorisation (item) {
      try {
        // Send parental authorization request to fillactive-api
        const response = await fetch(getSiteUrl() + '/contact/authorization', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ id: item.id })
        });

        if (response.status !== 200) {
          this.$reportError({message: this.$t('errors.error')});
          return;
        }

        this.$reportSuccess({message: this.$t('dialogs.send_parental_authorization')});
      } catch (error) {
        console.error(error);
        this.$reportError({message: `${error.message}`});
      }
    },
    /**
     * Open dialog to confirm deletion of object
     *
     * @param    {Object}  item  The item to delete
     * @returns  {null}
     */
    handleDeleteDialog (item) {
      if (item !== null && item.id !== undefined && this.typeConfig.idSingular !== undefined) {
        const itemType = `${this.typeConfig.idSingular.charAt(0).toUpperCase()}${this.typeConfig.idSingular.slice(1)}`;
        this.customDialogTitle = this.typeConfig.verbose_label_singular === undefined
          ? this.$t('dialogs.remove_title')
          : `${this.$t('ui.delete')} ${this.$t(this.typeConfig.verbose_label_singular)}`;
        // Fallback, delete modal will use item ID
        let itemTitle = item.id;
        // If the itemBreadcrumb is defined use that instead
        const { itemBreadcrumb } = this.typeConfig;
        const breadcrumb = get(item, itemBreadcrumb, item.title);
        if (breadcrumb !== undefined) {
          itemTitle = breadcrumb;
        }
        this.customDialogSubtitle = this.$t('dialogs.remove_subtitle_dynamic', { title: itemTitle });
        this.customDialogAction = () => this.executeDeleteMutation({
          mutationName: `delete${itemType}`,
          targetId: item.id,
        });
        this.customDialogShowAction = true;
        this.customDialogOpen = true;
      }
      this.$ga.event({
        eventCategory: 'crud',
        eventAction: 'ui/button/delete',
        eventLabel: `module: ${this.typeName}`,
      });
    },
    /**
     * Handle move item.
     * Request parent id mutation when item is moved through drag and drop.
     *
     * @param {Object} event.item - The item moved
     * @param {number} event.parentId - The new parent id
     */
    async handleMoveItem ({ item, parentId, index }) {
      const fields = [
        { key: this.typeConfig.itemParentId, type: 'number' }
      ];
      const variables = { [this.typeConfig.itemParentId]: parentId };
      if (this.typeConfig.rankable === true) {
        fields.push({ key: 'rank', type: 'number' });
        variables.rank = index;
      }
      try {
        await this.executePatchMutation({
          mutationName: this.getPatchMutationName(this.typeConfig.idSingular),
          targetId: item.id,
          fields,
        }, variables);

        this.$reportSuccess({ message: this.$t('dialogs.update_success') });
      } catch (error) {
        console.error(error);

        this.$reportError({ message: `${error.message}` });
      }
    },
    /**
     * Handle item field update event.
     * Request mutation when table column value is updated.
     *
     * @param {Object} event.item - The edited item
     * @param {string} key - The field key
     * @param {*} value - The new field value
     */
    async handleUpdateItemField ({ item, key, value }) {
      this.quickUpdateItemField({
        idSingular: this.typeConfig.idSingular,
        fields: this.fields,
        item,
        key,
        value,
      });
    },
    /**
     * Gets a usable sortBy value from the table props
     * @returns {string}
     */
    getSortByValue () {
      return typeof this.options.sortBy[0] === 'string'
        ? this.options.sortBy[0]
        : '';
    },
    /**
     * Gets a usable descending value from the table props
     * @returns {boolean}
     */
    getDescendingValue () {
      return typeof this.options.descending[0] === 'boolean'
        ? this.options.descending[0]
        : true;
    },
    /**
     * Gets a usable filter value from the selected filters
     * @returns {string}
     */
    getFilterValue () {
      // Build array of all filters selected by user
      const selectedFiltersArray = Object.keys(this.selectedFilters)
        .map(selectedFilterId => {
          const filterValue = this.selectedFilters[selectedFilterId];
          const filterConfig = this.typeConfig.filters.find(filter => filter.id === selectedFilterId);

          if (selectedFilterId === 'dates' && this.selectedFilters[selectedFilterId].length > 0) {
            // Handle filter dates with range values
            let result = `${filterConfig.whereClause[0]}:${this.selectedFilters[selectedFilterId][0]}`;

            if (this.selectedFilters[selectedFilterId][1]) {
              result = `${result},${filterConfig.whereClause[1]}:${this.selectedFilters[selectedFilterId][1]} 23-59-59`;
            }

            return result;
          } else if (typeof filterValue === 'object') {
            // Handle multiple filter values
            const { clauseSeparator } = filterConfig;
            const separator = clauseSeparator !== undefined ? clauseSeparator : '(&&)';
            const concatValues = filterValue.map(item => {
              return item.value;
            }).join(separator);

            return `${filterConfig.whereClause}:${concatValues}`;
          }

          if (filterValue === undefined || filterConfig == null) {
            return null;
          }

          return `${filterConfig.whereClause}:${filterValue}`;
        })
        .filter(filterItem => filterItem !== null);

      // Add filters from config in case of related modules
      if (this.relatedFiltersString !== '' && this.relatedFiltersString !== null) {
        selectedFiltersArray.push(this.relatedFiltersString);
      }

      return selectedFiltersArray.join(',');
    },
    toggleFilters () {
      this.showFilters = !this.showFilters;
      this.$ga.event({
        eventCategory: 'crud',
        eventAction: 'filters/toggle-visibility',
        eventLabel: `module: ${this.typeName} - visible: ${this.showFilters}`,
      });
    },
    updateFilters ({ key, value }) {
      if (key == null) {
        this.selectedFilters = value;
      } else if (value === undefined) {
        this.$delete(this.selectedFilters, key);
      } else {
        this.selectedFilters = { ...this.selectedFilters };
        this.$set(this.selectedFilters, key, value);
      }
    },
    openAddDialog () {
      this.$ga.event({
        eventCategory: 'crud',
        eventAction: 'ui/button/add',
        eventLabel: `module: ${this.typeName}`,
      });
      this.addItemDialog = true;
    },
    closeAddDialog () {
      this.addItemDialog = false;
    },
    refetch () {
      this.$apollo.queries.rows.refetch();
    },
    getDataQuery () {
      // Set current type
      this.currentType = this.typeName;

      // Create query options
      const { itemsPerPage } = this;
      const { page } = this.options;

      const sortBy = this.getSortByValue();
      const descending = this.getDescendingValue();
      const filter = this.getFilterValue();

      // Ensure we fetch id even if it is not listed
      const queryFields = this.listingFetchFields;

      if (this.itemKey !== 'id' && queryFields.some(field => field.select_id !== undefined) === false) {
        queryFields.push({ select_id: `${this.itemKey}: id` });
      }

      const queryOptions = {
        type: this.typeName,
        fields: queryFields,
        filter
      };

      // Set search options
      if (this.search != null && this.search !== '') {
        queryOptions.searchFields = this.searchFields.map(field => field.key).join();
        queryOptions.search = this.search;
      }

      // Treeview specific options
      if (this.viewType === 'treeview') {
        queryOptions.maxLevel = this.typeConfig.treeviewMaxLevel;

        return this.createTreeviewQuery(queryOptions);
      // Paginate and sort only on table listing
      } else {
        queryOptions.additionalQueryParams = this.additionalQueryParams;
        queryOptions.page = page;
        queryOptions.itemsPerPage = itemsPerPage;
        queryOptions.sortBy = sortBy;
        queryOptions.descending = descending;

        return this.createListingQuery(queryOptions);
      }
    },
    handleUpdateItemsPerPage (value) {
      this.options.itemsPerPage = value;

      this.handleTableAnalytics(value, 'itemsPerPage');
    },
    handleTableAnalytics (value, type) {
      this.$ga.event({
        eventCategory: 'crud',
        eventAction: type === 'itemsPerPage' ? 'ui/items-per-page' : 'ui/pagination',
        eventLabel: `module: ${this.typeName} - ${type === 'itemsPerPage' ? 'items': 'page'}: ${value}`,
      });
    },
    async handleExportClick (format = 'xls') {
      if (format === 'xls') {
        this.exportXLS();
      } else {
        this.exportCSV();
      }
      this.$ga.event({
        eventCategory: 'crud',
        eventAction: `ui/button/export/${format}`,
        eventLabel: `module: ${this.typeName}`,
      });
    },
    /*
     * Manually add or remove item to selected array
     *
     * Returns {void}
     */
    addItemToSelected (item) {
      let newSelected = cloneDeep(this.selected);
      // If Item is already selected remove it
      if (this.selected.some(({ id }) => id === item.id) === true) {
        newSelected = this.selected.filter(({ id }) => id !== item.id);
      } else {
        newSelected.push(item);
      }
      // Update the selection
      this.$emit('update:selected', newSelected);
    },
    /*
     * Dispatch action for table row click
     *
     * Returns {void}
     */
    handleTableRowClick (item) {
      // Clicking on table row results in accessing item edit page
      if (this.isRelationsListing === false && this.editIsEmpty === false) {
        this.goToEdit(this.typeConfig.id, item.id, this.typeName);
      } else if (this.isRelationsListing === true && this.selected) {
        // In the case of relations select, item is added to selected
        this.addItemToSelected(item);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .btn-add,
.export-menu__button, {
  @include rem(min-width, 36px, !important);
}

.table-wrapper {
  @include rem(border-radius, 4px);
}

.export-actionblocker {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: 25;
}
</style>
